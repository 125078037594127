import React, { Fragment } from "react";
import { StyledPaper } from "../../components/styled-paper";
import { GetTopMakesInventory } from "../../../services/dashboard.service";
import { toast } from "../../../state/snackbar";
import { useBehaviourSubject } from "../../../hooks/user-obsevrable";
import { selectedMember$, user$ } from "../../../state/user";
import { IBucket } from "../../module.interface";
import { StyledDonutChart } from "../../../charts-library/donut-chart";
import take from "lodash/take";
import { FullScreenModal } from "../../../components/app.full-screen.modal";
import { SimplifiedTable } from "../../../components/simplified-table";
import { UUIDGenerator } from "../../../util/uuid.generator";
import { Typography } from "@mui/material";
import useDeepCompareEffect from "use-deep-compare-effect";

export const TopMakesDonutChart: React.FC = () => {
  const selectedMember = useBehaviourSubject(selectedMember$);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IBucket[]>([]);
  const [chartData, setChartData] = React.useState<IBucket[]>([]);
  const user = useBehaviourSubject(user$);
  const fetchData = () => {
    if (user) {
      setLoading(true);
      GetTopMakesInventory()
        .then((res) => {
          setData(res.data);
          setChartData(take(res.data, 5));
        })
        .catch(() => {
          toast("Erorr happened while fetching top makes", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useDeepCompareEffect(fetchData, [user, selectedMember]);
  return (
    <Fragment>
      <StyledPaper
        title="Top 5 Car Makes"
        subtitle="Overview of your car makes"
        expandDetails={true}
        onExpand={() => {
          setIsDetailsOpen(true);
        }}
      >
        <StyledDonutChart
          labels={chartData.map((i) => i.key)}
          chartSeries={chartData.map((i) => i.doc_count)}
          loading={loading}
          title="Total Cars"
        />
      </StyledPaper>
      {isDetailsOpen ? (
        <FullScreenModal
          isOpen={isDetailsOpen}
          onClose={() => {
            setIsDetailsOpen(false);
          }}
          width="md"
          title="Car Makes List"
          subTitle="List of Car makes added to your inventory"
          content={
            <SimplifiedTable
              loading={loading}
              docs={data.map((i) => {
                return {
                  make_name: i.key,
                  doc_count: i.doc_count,
                  _id: UUIDGenerator(),
                };
              })}
              colDefs={[
                {
                  masterColumnTitle: "Make Name",
                  masterColumnName: "doc_count",
                  renderType: "text",
                  cellRender: (params) => (
                    <Typography>{params.make_name}</Typography>
                  ),
                },
                {
                  masterColumnTitle: "Cars Added",
                  masterColumnName: "doc_count",
                  renderType: "text",
                },
              ]}
            />
          }
        />
      ) : null}
    </Fragment>
  );
};
